.car-item {
    color: #fff;
    text-align: center;
    padding: 3%;
}

.car-item .image img {
    width: auto;
    height: 200px;
    max-height: 200px;
    height: auto;
    max-width: 100%;
}

.car-item .call-to-action {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
}

.car-item .call-to-action button {
    margin-left: 0.55rem;
    margin-right: 0.55rem;
}
.rooms .swiper-slide {
    position: relative;
}


.rooms .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    position: relative;


    display: block;
    width: auto;
    padding: 8px;
    margin-right: 8px;
}

.rooms {
    display: flex;
    max-width: auto;
    overflow: scroll;
}

.rooms .room {
    position: relative;
    width: auto;
}

.rooms img {
    height: auto;
    width: auto;
    height: 400px;
}

.rooms .ant-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    background: none;
    border: none;
    width: 80px;
    height: 80px;
    display: block;
    color: #512e88;
    font-size: 2rem;
    margin-left: -40px;
    background: white;

box-shadow: 0px 0px 0px 5px #512e88!important;
opacity: 0.8;
}





@media screen and (max-width: 576px) {

  }
  
#header-top {
    display: flex;
    padding: 3%;
}

#header-top>div {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#header-top .call-to-action {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 768px) {

    #header-top {
        width: 94%;
        display: block;
    }

   
    #header-top>div,
    #header-top>div>div {
        display: block!important;
        text-align: center;
        width: 100%;
        padding-top: 2%;;
    }
    
  }
  
@import url("https://use.typekit.net/oym8ary.css");


html,body {
  background: radial-gradient(circle, rgba(209,183,255,1) 0%, rgb(81, 46, 136) 56%);
  min-width: 100vw;
  min-height: 100vh;
}

sup {
  font-weight: 400;
  font-size: 50%;
}

.App {
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
 
  background: rgb(209,183,255);
background: radial-gradient(circle, rgba(209,183,255,1) 0%, rgb(81, 46, 136) 56%);

font-family: "source-sans-pro", sans-serif;
font-weight: 400;
font-style: normal;
}


.ant-modal {
  font-family: "source-sans-pro", sans-serif;
font-weight: 400;
font-style: normal;
}

body .ant-modal-close {
  width: 40px!important;
  height: 40px!important;
  background-color: white!important;
}


.swiper-button-prev::after {
  position: relative;
  left: -0.2rem;
}

.swiper-button-next::after {
  position: relative;
  right: -0.2rem;
}

#content {
  overflow: hidden;
  max-width: 100%;
}

.swiper {
  margin-left: -1rem!important;
  margin-right: -1rem!important;
}

.swiper-button-prev, .swiper-button-next {
  width: 50px!important;
  height: 50px!important;
  border-radius: 100%;
  background-color: rgba(81, 46, 136,0.9);
  padding: 0.5rem;
  color: white!important;
  margin-top: 0rem!important;
}



h1 {
  font-size: 4.025rem;
  line-height: normal;
  margin: 0px;
  font-weight: 500;
}

h2 {
  font-weight: 600;
}

.ant-btn {
  border-radius: 3rem;
  background: none;
  border: 1px white solid;
  height: 43px;
  padding: 4px 25px;
  line-height: 34px;
  outline: none!important;
  box-shadow: none!important;
}

.ant-btn:hover {
  background-color: white!important;
  color: rgba(81,46,136,1)!important;
}



.App #content {
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 3%;
}


@media screen and (max-width: 768px) {


  .App {
    text-align: center;
  }

  .swiper-slide {
    width: 100%!important;
  }
  
}




.swiper-slide {
  margin: 0px!important;
}






.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

footer {
    padding: 3%;
    color: white;
}




@media screen and (max-width: 768px) {


    footer {
        position: relative;
        text-align: center;
    }
    
  }